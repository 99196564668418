import DemoFormSection from "components/common/DemoFormSection";
import Features from "components/common/Features";
import WistiaVideoHeader from "components/common/hero/WistiaVideoHeader";
import InfoSlider from "components/common/InfoSlider";
import TrustedPartnerSection from "components/common/TrustedPartnerSection";
import IndustryFeaturedUpdated from "components/industries/updated/IndustryFeaturedUpdated";
import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import { Helmet } from "react-helmet";

const LandingPageWithVideo = ({
  metaTitle,
  metaDescription,
  ogImage,
  headerSection,
  logoSection,
  infoSlideSection,
  featureSection,
  formSection,
  autoPlay,
}) => {
  const { preTitle, title, blurb, videoId, button } = headerSection;
  const { logos, logoTitle } = logoSection;
  const { slides, infoSlideTitle } = infoSlideSection;
  const { features, featureTitle } = featureSection;

  return (
    <GlobalLayout color="var(--gradient-light)" landingPage>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {ogImage && <meta property="og:image" content={ogImage} />}
        <script
          src={`https://fast.wistia.com/embed/medias/${videoId}.jsonp`}
          async
        />
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async />
      </Helmet>
      <WistiaVideoHeader
        blurb={blurb}
        button={button}
        preTitle={preTitle}
        title={title}
        videoId={videoId}
        autoPlay={autoPlay}
      />
      <IndustryFeaturedUpdated
        title={logoTitle}
        logos={logos}
        color="var(--white)"
      />
      <InfoSlider
        data={slides}
        title={infoSlideTitle}
        color="var(--nuetral-100)"
      />
      <Features data={features} title={featureTitle} color="var(--white)" />
      <TrustedPartnerSection button={false} color="var(--nuetral-100)" />
      <DemoFormSection data={formSection} color="var(--white)" />
    </GlobalLayout>
  );
};

export default LandingPageWithVideo;
