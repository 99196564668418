import SlideInContainer from "components/common/SlideInContainer";
import PageContent from "components/page/PageContent";
import { TitleMed } from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "linaria";
import React from "react";

const sectionContainer = css`
  display: flex;
  justify-content: center;
`;

const contentContainer = css`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
`;

const contentClass = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 3.2rem;
`;

const headerContainer = css`
  display: flex;
  justify-content: center;
  h2 {
    text-align: center;
  }
`;

const logoWrapper = css`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media (max-width: 975px) {
    width: 30%;
  }
  @media (max-width: 650px) {
    width: 45%;
  }
`;

const imageContainerClass = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  align-self: center;
  flex-wrap: wrap;
  @media (min-width: 1440px) {
    padding: 0 107px;
  }
`;

const imageClass = css`
  width: 135px;
  pointer-events: none;
`;

const IndustryFeaturedUpdated = ({
  title,
  logos,
  color = "var(--nuetral-100)",
}) => {
  return (
    <section className={sectionContainer} style={{ background: color }}>
      <div className={contentContainer}>
        <PageContent>
          <div className={contentClass}>
            <SlideInContainer>
              <div className={headerContainer}>
                <TitleMed>{title}</TitleMed>
              </div>
            </SlideInContainer>
            <SlideInContainer>
              <div className={imageContainerClass}>
                {logos.map(logo => {
                  const logoRef = getImage(logo.img);
                  return (
                    <div className={logoWrapper} key={logo.alt}>
                      <GatsbyImage
                        image={logoRef}
                        style={{ maxWidth: logo.width }}
                        alt={logo.alt}
                        className={imageClass}
                      />
                    </div>
                  );
                })}
              </div>
            </SlideInContainer>
          </div>
        </PageContent>
      </div>
    </section>
  );
};

export default IndustryFeaturedUpdated;
