import LandingPageWithVideo from "components/landing-pages/templates/LandingPageWithVideo";
import { graphql, StaticQuery } from "gatsby";
import React from "react";

export default function LPRLandingPage() {
  const renderContent = data => {
    const headerSection = {
      preTitle: "LICENSE PLATE READER INSTANT DEMO",
      title: "Monitor Parking Lots and Building Perimeters",
      blurb:
        "Rhombus License Plate Recognition efficiently secures garages, parking lots, and building perimeters by recognizing, capturing, and reading license plates—offering real-time AI-powered vehicle alerts.",
      button: true,
      videoId: "a6b8b9802b",
    };

    const logoSection = {
      logoTitle: "Trusted by Industry Leaders ",
      logos: [
        {
          img: data.logo1,
          width: "118px",
          alt: "Amazon",
        },
        {
          img: data.logo2,
          width: "130px",
          alt: "Hurley",
        },
        {
          img: data.logo3,
          width: "177px",
          alt: "Aramark",
        },
        {
          img: data.logo4,
          width: "115px",
          alt: "Stryker",
        },
        {
          img: data.logo5,
          width: "149px",
          alt: "Goodwill",
        },
        {
          img: data.logo6,
          width: "137px",
          alt: "Clark Contruction",
        },
      ],
    };

    const infoSlideSection = {
      infoSlideTitle: "The Benefits of Rhombus",
      slides: [
        {
          tabTitle: "AI Analytics",
          title:
            "Automatically Gather Vehicle Video Evidence and Get Real-Time Alerts",
          description:
            "Rhombus’ built-in AI analytics instantly detect, capture, and analyze vehicle-related video evidence. Auto-generate video clips of vehicle events as soon as a car, truck, or motorcycle arrives on premise, and stay aware of onsite activity with real-time alerts for specific vehicles. ",
          image: data.infoSlide1,
        },
        {
          tabTitle: "Intuitive Management",
          title: "Easily Acquire, Label, and Organize Vehicle Data",
          description:
            "Track and manage onsite vehicles more intuitively by adding custom names and labels, such as Employee Car, Permitted Vehicle, Untrusted Vehicle, and more. Save time with automatic workflows to organize vehicle data, export vehicle counting data in CSV, and securely share reports and video footage. ",
          image: data.infoSlide2,
        },
        {
          tabTitle: "Smart Search",
          title:
            "Quickly Find Footage of Vehicles and Incidents with Smart Search",
          description:
            "No more manually scrubbing through footage—speed up investigations with AI-powered filters. Easily search for vehicles by plate number, location, date range, and custom label. Rhombus automatically finds all relevant video clips for you, making incident review a breeze.",
          image: data.infoSlide3,
        },
        {
          tabTitle: "All-in-One Solution",
          title: "User-Friendly LPR With a Single, Cloud-Based Camera",
          description:
            "Other LPR solutions need two cameras to work—one to detect vehicles and capture plates, another to actually read the plates. Rhombus License Plate Recognition lets you do it all with a single camera you can deploy in minutes and manage from anywhere.  ",
          image: data.infoSlide4,
        },
      ],
    };

    const featureSection = {
      featureTitle: "Rhombus Platform Features",
      features: [
        {
          icon: data.icon1,
          iconAlt: "User-Friendly",
          title: "User-Friendly",
          p:
            "Rhombus combines consumer-level ease of use with enterprise-grade performance, no technical expertise required.",
        },
        {
          icon: data.icon2,
          iconAlt: "AI Analytics",
          title: "AI Analytics",
          p:
            "Rhombus AI uses machine learning to save time, accelerate investigations, and send real-time intelligent alerts.  ",
        },
        {
          icon: data.icon3,
          iconAlt: "Bandwidth & Latency",
          title: "Bandwidth & Latency",
          p:
            "Get the best performance with 50% less bandwidth utilization and 10x better latency compared to other cloud vendors.",
        },
        {
          icon: data.icon4,
          iconAlt: "Infinite Scalability",
          title: "Infinite Scalability",
          p:
            "Scale geographically and vertically within your organization with unlimited devices, locations, and users.",
        },
      ],
    };

    const formSection = {
      preTitle: "REQUEST DEMO",
      title: "Interested in Rhombus?",
      description:
        "Get a demo today and see how Rhombus can transform your physical security.",
      learnLines: [
        "Why a cloud-native solution is superior to on-premise video security systems",
        "The benefits of using a unified platform with smart cameras, sensors, and integrations",
        "How to improve safety and productivity with powerful AI analytics",
      ],
      id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
    };

    return (
      <LandingPageWithVideo
        metaTitle="License Plate Reader Instant Demo |  Rhombus™"
        metaDescription="Secures garages, parking lots, and building perimeters with Rhombus."
        ogImage="https://rhombus.com/img/OG-img-lpr-min.png/"
        headerSection={headerSection}
        logoSection={logoSection}
        infoSlideSection={infoSlideSection}
        featureSection={featureSection}
        formSection={formSection}
        autoPlay
      />
    );
  };
  const query = graphql`
    query {
      logo1: file(relativePath: { eq: "components/common/logos/amazon.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(relativePath: { eq: "components/common/logos/hurley.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/aramark-black.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(relativePath: { eq: "components/common/logos/stryker.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-2-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-black.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      infoSlide1: file(
        relativePath: {
          eq: "components/landing-pages/search/img/lpr-ai-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: {
          eq: "components/landing-pages/search/img/intuitive-management-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: {
          eq: "components/landing-pages/search/img/lpr-smart-search-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: {
          eq: "components/landing-pages/search/img/all-in-one-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/system.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/learning.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/location.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
