import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  MainParagraph,
  PreTitle,
} from "components/rhombus-UI/theme/typography";
import { styled } from "linaria/react";
import React from "react";

import RhombusButton from "../RhombusButton";
import { toTitleCase } from "../util/helperFunctions";

const WistiaVideoHeaderInner = styled(SectionInner)`
  gap: 5rem;
  @media (max-width: 1175px) {
    flex-direction: column;
  }
`;

const WistiaVideoHeaderColumns = styled(SectionCol)`
  @media (max-width: 1175px) {
    width: 100%;
  }
`;

const WistiaVideoHeader = ({
  preTitle,
  title,
  blurb,
  videoId,
  button,
  autoPlay = false,
}) => {
  return (
    <SectionContainer
      color="var(--gradient-light)"
      style={{ minHeight: "690px" }}
    >
      <WistiaVideoHeaderInner>
        <WistiaVideoHeaderColumns className="col">
          <TextContainer>
            <PreTitle>{preTitle}</PreTitle>
            <FancyPageTitle>{toTitleCase(title)}</FancyPageTitle>
            <MainParagraph>{blurb}</MainParagraph>
          </TextContainer>
          {button && <RhombusButton type="primary" title="Request Demo" />}
        </WistiaVideoHeaderColumns>
        <WistiaVideoHeaderColumns
          style={{ display: "inline-block", margin: "auto" }}
        >
          <div
            className="wistia_responsive_padding"
            style={{ padding: "56.25% 0 0 0", position: "relative" }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: "100%",
                left: "0",
                position: "absolute",
                top: "0",
                bottom: "0",
                margin: "auto",
                width: "100%",
              }}
            >
              <span
                className={`wistia_embed wistia_async_${videoId} popover=${!autoPlay} popoverAnimateThumbnail=true videoFoam=true`}
                style={{
                  display: "inline-block",
                  height: "100%",
                  position: "relative",
                  width: "100%",
                }}
              >
                &nbsp;
              </span>
            </div>
          </div>
        </WistiaVideoHeaderColumns>
      </WistiaVideoHeaderInner>
    </SectionContainer>
  );
};

export default WistiaVideoHeader;
